import {
  CellId,
  ComputedCellReferenceMapV3,
  ComputedCellReferencesV3,
  DropdownOptions,
  NoCodeCellDataframe,
  ParameterName,
  PythonCellReferencesV2,
  StoredCellReferencesV3,
  StoredSqlReferencesV3,
  StringArray,
  assertNever,
  convertJinjaSqlReferences,
  getCalcsCellReferencesV2,
  getChartReferencedDataFrames,
  getComputedCodeCellReferencesV3,
  getComputedFilterCellReferencesV3,
  getComputedGenericCellReferencesV3,
  getComputedNoCodeCellReferencesV3,
  getComputedSqlCellReferencesV3,
  guardNever,
  mergeCellReferencesV2,
  upgradeCellReferencesV2,
} from "@hex/common";

import { CellContentsMP, CellMP } from "../redux/slices/hexVersionMPSlice";

import { getCellReferencesV2 } from "./getCellReferences";

const getCellReferencesV3 = (
  cell: CellMP,
  cellContents: CellContentsMP,
  parameterOptions: Map<ParameterName, string[]>,
): ComputedCellReferencesV3[] => {
  switch (cellContents.__typename) {
    case "CodeCell":
    case "MetricCell":
    case "MarkdownCell":
    case "TextCell":
    case "Parameter":
    case "DbtMetricCell":
    case "VegaChartCell":
    case "MapCell":
    case "ComponentImportCell":
    case "BlockCell":
    case "WritebackCell": {
      const refs = getCellReferencesV2(cell, cellContents);
      if (refs == null) {
        return [];
      } else if (PythonCellReferencesV2.guard(refs)) {
        return [
          getComputedCodeCellReferencesV3(upgradeCellReferencesV2(refs, null)),
        ];
      } else {
        return [
          getComputedGenericCellReferencesV3(
            upgradeCellReferencesV2(refs, null),
          ),
        ];
      }
    }
    case "ChartCell":
    case "PivotCell":
    case "DisplayTableCell":
    case "ExploreCell": {
      const refs = getCellReferencesV2(cell, cellContents);
      if (refs == null) {
        return [];
      } else {
        const refsV3 = upgradeCellReferencesV2(refs, null);
        if (
          cellContents.__typename === "DisplayTableCell" ||
          cellContents.__typename === "PivotCell"
        ) {
          return [
            getComputedNoCodeCellReferencesV3({
              dataframe: cellContents.dataframe,
              references: refsV3,
            }),
          ];
        } else if (cellContents.__typename === "ExploreCell") {
          if (NoCodeCellDataframe.guard(cellContents.exploreDataframe)) {
            getComputedNoCodeCellReferencesV3({
              dataframe: cellContents.exploreDataframe,
              references: refsV3,
            });
          }
          // TODO(EXP-1135) - figure out references for semantic dataset input
          return [];
        } else if (cellContents.__typename === "ChartCell") {
          const dataframe = getChartReferencedDataFrames(
            cellContents.chartSpec,
          )[0];
          return [
            getComputedNoCodeCellReferencesV3({
              dataframe: dataframe,
              references: refsV3,
            }),
          ];
        } else {
          guardNever(cellContents, cellContents);
        }
        return [];
      }
    }
    case "FilterCell": {
      return [getComputedFilterCellReferencesV3(cellContents)];
    }
    case "SqlCell": {
      let sqlRefs: StoredSqlReferencesV3 | null = null;
      let jinjaRefs: StoredCellReferencesV3 | null = null;
      if (cellContents.jinjaSqlReferences != null) {
        [sqlRefs, jinjaRefs] = convertJinjaSqlReferences(
          cellContents.jinjaSqlReferences,
          parameterOptions,
        );
      } else {
        sqlRefs = cellContents.sqlCellReferencesV3;
        jinjaRefs = cellContents.jinjaCellReferencesV3;
      }

      const res: ComputedCellReferencesV3[] = [
        getComputedSqlCellReferencesV3(sqlRefs ?? undefined, cellContents),
      ];
      if (jinjaRefs != null) {
        res.push(getComputedGenericCellReferencesV3(jinjaRefs));
      }

      const otherReferences = upgradeCellReferencesV2(
        mergeCellReferencesV2(
          cellContents.sqlDisplayTableConfig?.conditionalFormattingReferences,
          cellContents.sqlDisplayTableConfig?.filtersReferences,
          getCalcsCellReferencesV2(cellContents.sqlDisplayTableConfig?.calcs),
        ),
        null,
      );
      res.push(getComputedGenericCellReferencesV3(otherReferences));

      return res;
    }

    default:
      assertNever(cellContents, cellContents);
  }
};

// This should have the same logic as getCellReferenceMapV3 in the server
// This is only used for display things in the UI currently, so if there are inconsistencies it is not catastrophic
// If this becomes used for creating mutation/MP operations, then we should make 100% sure its consistent with backend so it doesn't get out of sync
export const getCellReferenceMapV3 = (
  cellsMap: Record<CellId, CellMP | undefined>,
  cellContentsMap: Record<CellId, CellContentsMP | undefined>,
): ComputedCellReferenceMapV3<CellMP> => {
  const cellMap: ComputedCellReferenceMapV3<CellMP> = {};
  const parameterOptions: Map<ParameterName, string[]> = new Map();

  for (const cellContents of Object.values(cellContentsMap)) {
    if (cellContents?.__typename === "Parameter") {
      if (DropdownOptions.guard(cellContents.options)) {
        if (StringArray.guard(cellContents.options.valueOptions)) {
          const options = parameterOptions.get(cellContents.name);
          if (options == null) {
            parameterOptions.set(cellContents.name, [
              ...cellContents.options.valueOptions,
            ]);
          } else {
            options.push(...cellContents.options.valueOptions);
          }
        }
      }
    }
  }

  for (const cell of Object.values(cellsMap)) {
    if (!cell || cell.deletedDate != null) {
      continue;
    }
    const cellContents = cellContentsMap[cell.id];
    if (!cellContents || cellContents.deletedDate != null) {
      continue;
    }
    const references = getCellReferencesV3(
      cell,
      cellContents,
      parameterOptions,
    );
    cellMap[cell.id] = {
      cell,
      references,
    };
  }
  return cellMap;
};
